import '../assets/css/nds.css';

import React, { Component } from "react"
import SignatureCanvas from 'react-signature-canvas'
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Slider from 'rc-slider';
import '../assets/css/slider.css';

import Layout from "../components/layout"
import Steps from "../components/ndstep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyCheckbox from "../components/MyCheckbox";
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import infosvg from "../images/info.svg"


class nds5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      newsub:false,
      infoError:null,
      nds5Data:{name:'',title:'',phone:'',email:'',w9form:false,dealStructure:'0',products:[]},
      selectedFile:null,
      marketVal:3, 
      marketDet:{
        1:{title:"Very Soft Approach", desc:"Lowest results, email only."},
        2:{title:"Soft Approach", desc:"Email and light phone activity."},
        3:{title:"Moderate Approach", desc:"Email, phone, and texting activity."},
        4:{title:"Active Approach", desc:"Increased email, phone, and texting activity."},
        5:{title:"Gung-Ho!", desc:"Persistent email, phone, and texting activity; best results!"}
      }
    };
   
  }
  sigPad = {}
  
  componentDidMount = async () => {
    if(!sessionStorage.nds3Data){
      navigate("/nds1"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
    }
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    }
    if(!sessionStorage.nds5Data){
        sessionStorage.nds5Data = window.btoa(JSON.stringify(this.state.nds5Data))
    } 
    this.state.nds5Data = JSON.parse(window.atob(sessionStorage.nds5Data))
    
    this.setState({nds5Data:JSON.parse(window.atob(sessionStorage.nds5Data))});

    let isAgent = sessionStorage.isAgent==='yes'?true:false;
    this.setState({isAgent:isAgent})
    console.log(process.env.BEDFORD_API_URL+"5a");
    
  }

  
  clear = () => {
    this.sigPad.clear();
    this.setState({ canSubmit: false });
    //this.checkMealso();
  }
  

  checkMealso = () => {
    let drw = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    if(drw.length>5000){
      this.setState({ canSubmit: true });
    }
  }

  
  submit = async(model) => {
    const { isAgent } = this.state;
        const { showLoader, hideLoader} = this.props;
        showLoader();
        const data = new FormData();
        let prds = JSON.parse(window.atob(sessionStorage.nds3Data));
        let i = prds.ProductActivate.indexOf("Other");
        prds.ProductActivate[i] = "Other : "+prds.Other;

        //console.log(prds.ProductActivate);
        /* let info = {
            'producer': JSON.parse(window.atob(sessionStorage.nds1Data)),
            'contact': JSON.parse(window.atob(sessionStorage.nds2Data)),
            'products': prds,
            'dmsType': JSON.parse(window.atob(sessionStorage.nds4Data)),
            'payee':{}
        } */
        let info = {
          'producer': JSON.parse(window.atob(sessionStorage.nds1Data)),
          'contact': {},
          'products': prds,
          'dmsType': {},
          'payee':{}
      }
        if(!isAgent)
          data.append('sign',this.sigPad.getTrimmedCanvas().toDataURL('image/png'))

        data.append('isAgent',isAgent?1:0)
        data.append('issc',0)
        data.append('b2b','DOWC')
        data.append('agrinfo',JSON.stringify(info))
        
        
      await fetch(process.env.BEDFORD_API_URL,{method: "POST", body: data})
      .then(res => res.json())
      .then((res) => {
        sessionStorage.clear();
        hideLoader();
        navigate("/paok/?type=DOWC");
      });
    
  }

  onChangeHandler=event=>{
    this.setState({ selectedFile: event.target.files[0]})
    this.state.selectedFile = event.target.files[0];
    //this.checkMealso();
  }
   
  render() {
    const { canSubmit, newsub, infoError, isAgent } = this.state;
    const { buttonLoader} = this.props;

    
   
    return (
      <Layout layoutType="nds" >
        <section className="ndsec ndout">
        <Steps></Steps>
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h2>{'Business Owner'}</h2>
              </div>
            </div>

            <div className="body-content">
            <Formsy ref="nds5Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className="form-container-inline">
                    <div className="form-left">
                    <div className="form-label-group error">
                        <label>Please read the following and sign below *</label>
                        <small>
                        By your signature below, you are agreeing to be bound by the terms and conditions of the Producer Agreement, a sample of which can be 
                        accessed <a className="here" href="https://dowc-asserts.s3.amazonaws.com/ProducerAgreement20.pdf" target="_blank">here</a> prior 
                        to your signature. You represent that the information you have provided is true and correct and you understand that your signature will be applied to the Producer Agreement upon selecting “submit” below. Any further information needed to complete exhibits to the Producer Agreement will continue to be gathered during the remainder of the onboarding process and forwarded to you for signature.
                        </small>
                        {/* <SignatureCanvas penColor='green' canvasProps={{height: 150, className: 'sigCanvas'}} /> */}
                        {!isAgent?
                        <>
                        <SignatureCanvas canvasProps={{className: 'bosign'}} 
                        throttle={0} dotSize={2} minWidth={0.1} maxWidth={2} minDistance={1}
                        ref={(ref) => { this.sigPad = ref }}
                        onEnd={(e) => this.checkMealso()}
                        />
                        <a className="clearbtn" onClick={this.clear}>Clear</a>
                        </>
                        :
                        <div className="bosign ban" title='Not allowed to sign'></div>

                        }
                        
                        </div>
                        
                        
                    </div>
                    
                </div>
            
    
                <div className="error-txt">{infoError}</div>
                <div className="btn-container">
                  {!isAgent?
                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                        <ButtonLoader isLoading={buttonLoader} label={'Submit'} />
                    </button>
                  :
                    <button type="submit" className='btn wizard-btn creat-btn'>
                        <ButtonLoader isLoading={buttonLoader} label={'Share'} />
                    </button>
                  }
                </div>
            </Formsy>

              <div className="txtright">* Field is required</div>
            </div>
          </div>
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(nds5);